
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Box, Heading, Text } from 'grommet';
import Button from '../button';
import JoinButton from '../button/joinButton';

const PriceBox = ({ title, width, price }) => {
  return (
    <Box id="price-box" width={width} align="center">
      <Heading
        color="white"
        textAlign="center"
        size="small"
        level={1}
        margin={{ vertical: 'medium' }}
      >
        {title}
      </Heading>
      <Box
        pad="large"
        background="neutral-2"
        align="center"
        justify="between"
        fill
        responsive={false}
      >
        <Box>
          <Text color="white" weight="bold" size="large" textAlign="center" margin={{ bottom: "medium"}}>
            Premium Personal Coaching
          </Text>
        </Box>
        {/* <Box align="center">
          <Box
            margin={{ vertical: 'medium' }}
            align="end"
            justify="center"
            direction="row"
          >
            <Text
              level={3}
              color="black"
              as="span"
              size="xlarge"
              margin={{ right: '1.5rem' }}
            >
              ${price}
            </Text>
            <Text level={3} color="black" as="span" size="small">
              / mo
            </Text>
          </Box>
          <Text
            size="medium"
            color="white"
            textAlign="center"
            margin={{ bottom: 'medium' }}
          >
            Billed monthly
          </Text>
        </Box> */}
        <JoinButton />
      </Box>
    </Box>
  );
};

export default PriceBox;

PriceBox.propTypes = {
  width: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
